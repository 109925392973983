import {
  type Leaf,
  type RichTextElement,
  type RichTextRendererData,
} from '@/components/CMS/types'
import { CMSContainer } from '@/components/CMS/CMSContainer'
import { Body, Headline, Link } from '@shipt/design-system-typography'
// We will use this library to generate keys since the CMS backend doesn't give us any unique id for each element.
import { v4 as uuidv4 } from 'uuid'
import styled from 'styled-components'
import { CMSErrorHandler } from '@/components/CMS/CMSErrorHandler'
import { spacing } from '@/theme/tokens'
import { type Surface } from '@shipt/design-system-themes'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@shipt/design-system-tables'

// This renders rich text in the default colorway
export const renderElement = (element: RichTextElement) => {
  return _renderElement(element, 'default')
}

// This renders rich text against a dark
export const renderElementInverse = (element: RichTextElement) => {
  return _renderElement(element, 'inverse')
}

// This function is used to create components based on the element type supplied by the CMS data.
// It calls the renderChild function inside of the element which will either render out (stylized) text or another element.
const _renderElement = (
  element: RichTextElement,
  surface: Surface = 'default'
) => {
  const { type, children, optional_properties } = element
  switch (type) {
    case 'h1':
      return (
        <Heading
          as="h1"
          id={optional_properties?.header_id}
          key={uuidv4()}
          align={optional_properties?.align}
          surface={surface}
        >
          {children.map(renderChild(surface))}
        </Heading>
      )
    case 'h2':
      return (
        <Heading
          as="h2"
          size="md"
          id={optional_properties?.header_id}
          key={uuidv4()}
          align={optional_properties?.align}
          surface={surface}
        >
          {children.map(renderChild(surface))}
        </Heading>
      )
    case 'h3':
      return (
        <Heading
          as="h3"
          size="sm"
          id={optional_properties?.header_id}
          key={uuidv4()}
          align={optional_properties?.align}
          surface={surface}
        >
          {children.map(renderChild(surface))}
        </Heading>
      )
    case 'p': {
      if (children.length === 1 && children?.[0]?.text === '') {
        return <LineBreak key={uuidv4()} />
      } else {
        return (
          <CustomBody
            indent_level={optional_properties?.indent_level}
            key={uuidv4()}
            align={optional_properties?.align}
            surface={surface}
          >
            {children.map(renderChild(surface))}
          </CustomBody>
        )
      }
    }
    case 'ol':
      return (
        <Body
          as="ol"
          key={uuidv4()}
          align={optional_properties?.align}
          surface={surface}
        >
          {children.map(renderChild(surface))}
        </Body>
      )
    case 'ul':
      return (
        <Body
          as="ul"
          key={uuidv4()}
          align={optional_properties?.align}
          surface={surface}
        >
          {children.map(renderChild(surface))}
        </Body>
      )
    case 'li':
      return (
        <Body as="li" key={uuidv4()} surface={surface}>
          {children.map(renderChild(surface))}
        </Body>
      )
    case 'a':
      return (
        <Link
          key={uuidv4()}
          href={optional_properties.url}
          {...(optional_properties.open_in_new_tab
            ? { target: '_blank', rel: 'noreferrer' }
            : {})}
          surface={surface}
        >
          {children.map(renderChild(surface))}
        </Link>
      )
    case 'table': {
      return <Table key={uuidv4()}>{children.map(renderChild(surface))}</Table>
    }
    case 'td': {
      return (
        <RichTextTableCell key={uuidv4()}>
          {children.map(renderChild(surface))}
        </RichTextTableCell>
      )
    }
    case 'th': {
      return (
        <TableCell key={uuidv4()} variant="head">
          {children.map(renderChild(surface))}
        </TableCell>
      )
    }
    case 'tr': {
      return (
        <TableRow key={uuidv4()}>{children.map(renderChild(surface))}</TableRow>
      )
    }
    case 'tbody': {
      return (
        <TableBody key={uuidv4()}>
          {children.map(renderChild(surface))}
        </TableBody>
      )
    }
    case 'thead': {
      return (
        <TableHead key={uuidv4()}>
          {children.map(renderChild(surface))}
        </TableHead>
      )
    }
    default:
      return null
  }
}

// This function is used to render out formatted text nodes and wraps them with styling elements if necessary.
const renderChild = (surface: Surface) => (child: RichTextElement | Leaf) => {
  if ('text' in child) {
    if (child.italic) {
      return <i key={uuidv4()}>{child.text}</i>
    }
    if (child.bold) {
      return (
        <InlineElement type="bold" key={uuidv4()}>
          {child.text}
        </InlineElement>
      )
    }
    return child.text
  } else {
    // If the child is not a text node then it must be an element, so we recursively render it.
    return _renderElement(child, surface)
  }
}

export const truncateRichText = (
  elements: RichTextElement[],
  maxLength: number = 500
): RichTextElement[] => {
  maxLength = maxLength > 0 ? maxLength : 500
  let charCount = 0

  const truncateNode = (
    node: RichTextElement | Leaf
  ): RichTextElement | Leaf | null => {
    if (!node) return null
    if (charCount >= maxLength) return null

    if ('text' in node && node.text) {
      const remainingChars = maxLength - charCount
      const truncatedText = node.text.slice(0, remainingChars)
      charCount += truncatedText.length
      return { ...node, text: truncatedText }
    }

    if ('children' in node && node.children) {
      const truncatedChildren = node.children
        .map(truncateNode)
        .filter((child): child is Leaf => Boolean(child))
      return { ...node, children: truncatedChildren }
    }

    return node
  }
  return elements
    .map(truncateNode)
    .filter((element): element is RichTextElement => Boolean(element))
}

export const RichTextRenderer = ({
  content_type_id,
  id,
  data,
}: RichTextRendererData) => {
  const { elements } = data.legal_text
  try {
    const isTable = Boolean(
      elements.find((element) => element.type === 'table')
    )
    return (
      <CMSContainer contentTypeId={content_type_id} id={id}>
        <Article isConstrained={!isTable}>
          {elements.map(renderElement)}
        </Article>
      </CMSContainer>
    )
  } catch (error) {
    return (
      <CMSErrorHandler error={error} contentTypeId={content_type_id} id={id} />
    )
  }
}

const Heading = styled(Headline)<{ align?: 'left' | 'center' | 'right' }>`
  margin-bottom: ${spacing('sm')};
  text-align: ${(props) => props.align || 'inherit'};
`

const CustomBody = styled(Body)(({ indent_level }: { indent_level?: number }) =>
  indent_level ? { textIndent: `${indent_level}rem` } : {}
)

const LineBreak = styled.br`
  line-height: 1.5;
`

const InlineElement = styled.span<{ type: 'bold' }>((props) => {
  switch (props.type) {
    case 'bold':
      return { fontWeight: 'bold' }
  }
})

const Article = styled.article<{ isConstrained: boolean }>`
  max-width: ${({ isConstrained = true }) => (isConstrained ? '65ch' : null)};
  margin: ${spacing(0, 'auto')};
`

const RichTextTableCell = styled(TableCell)`
  vertical-align: top;
`
