import { apiPost } from '@/utils/dataFetching'
import { stringify } from '@/utils/queryString'

export type ZapierData = {
  _zip?: string
  date?: Date
  email?: string
  name?: string
  subject?: string
  message?: string
  'form-name'?: string | null
}

export enum ZapierSheetId {
  u4h4k5 = 'u4h4k5',
  u4hfpf = 'u4hfpf',
  u445ls = 'u445ls',
  bv9mbmp = 'bv9mbmp',
}

export const sendDataToZapier = (sheetId: ZapierSheetId, data: ZapierData) => {
  return apiPost({
    config: {
      url: `${sheetId}/`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: stringify(data),
    },
    options: {
      overrideUrl: `https://zapier.com/hooks/catch/631013`,
    },
    fetcherName: 'sendDataToZapier',
  })
}
