/**
 * Joins multiple class names into a single string with spaces between them.
 * Lightweight clsx or classnames library alternative.
 *
 * @example classNames(true && 'bold', false && 'hidden', [].length && 'hasItems')
 * @returns {string} 'bold'
 */

export const classNames = (...classes: unknown[]): string =>
  classes.filter(Boolean).join(' ')
