import {
  useState,
  type MouseEvent,
  type ReactNode,
  type MutableRefObject,
  useEffect,
} from 'react'
import styled, { css } from 'styled-components'
import { TimesCircledIcon } from '@shipt/design-system-icons'
import {
  type HasError,
  type InputLabelProps,
  type InputWrapperProps,
  type WrapperProps,
} from '@/elements/Inputs/types'
import {
  Body1FontStyles,
  InputContainer,
  HelperMessagesGroup,
  HelperText,
  InputButton,
} from '@/elements/Inputs/styles'
import { typographyAttributes } from '@shipt/design-system-typography'

type Props = InputWrapperProps & {
  children?: ReactNode
  isPII?: boolean
  shouldRemoveHelperTextGroupMargin?: boolean
}

export const InputWrapper = ({
  id,
  label,
  inputRef,
  renderInput,
  onFocus,
  onBlur,
  onChange,
  onKeyDown,
  onClear,
  value,
  helperTextLeft = '',
  helperTextRight = '',
  error = '',
  required = false,
  disabled = false,
  name,
  maxLength,
  children,
  ariaLabel,
  ariaDescribedBy,
  ariaInvalid,
  passwordrules,
  autoComplete,
  inputMode,
  enableClearButton = false,
  inputLabelAlt,
  isPII = false,
  shouldRemoveHelperTextGroupMargin = false,
  className = '', //Internally only for styled-components
}: Props) => {
  const [showLabelAndButton, setShowLabelAndButton] = useState(false)
  const [fieldLabel, setFieldLabel] = useState(
    inputLabelAlt ? (value ? label : inputLabelAlt) : label
  )
  useEffect(() => {
    setFieldLabel(inputLabelAlt ? (value ? label : inputLabelAlt) : label)
  }, [label, inputLabelAlt, value])

  // Don't render the label or button on the server
  useEffect(() => {
    setShowLabelAndButton(true)
  }, [])

  const handleClearInput = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    // ref type is marked as immutable when it actually isn't
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    if ((inputRef as MutableRefObject<HTMLInputElement>)?.current) {
      // ref type is marked as immutable when it actually isn't
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      onClear?.((inputRef as MutableRefObject<HTMLInputElement>).current)
    }
  }

  const handleClickInsideWrapper = () =>
    // ref type is marked as immutable when it actually isn't
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    (inputRef as MutableRefObject<HTMLInputElement>).current?.focus()

  const showClearButton = value && enableClearButton && showLabelAndButton
  const errorId = `${id}-error`
  const hasError = Boolean(error)

  const inputProps = {
    id,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    'aria-label': ariaLabel,
    'aria-describedby': ariaDescribedBy,
    'aria-invalid': ariaInvalid,
    passwordrules,
    value,
    required,
    disabled,
    maxLength,
    name,
    autoComplete,
    inputMode,
    ...(error && {
      hasError: true,
      'aria-invalid': true,
      'aria-describedby': errorId,
    }),
  }

  return (
    <InputContainer className={className}>
      <InputBorderWrapper
        onClick={handleClickInsideWrapper}
        onFocus={() => {
          fieldLabel !== label && setFieldLabel(label)
        }}
        onBlur={() => {
          const newFieldLabel = inputLabelAlt || label
          !value && setFieldLabel(newFieldLabel)
        }}
        hasError={hasError}
      >
        {showLabelAndButton && label && (
          <InputLabel
            {...typographyAttributes({ isPII })}
            htmlFor={id}
            hasError={hasError}
            inputHasValue={Boolean(value)}
          >
            {fieldLabel}
          </InputLabel>
        )}
        {renderInput(inputProps)}
        {children}
        {showClearButton && (
          <ClearInputButton
            onClick={handleClearInput}
            aria-label="Clear Entry"
            type="button"
            hasError={hasError}
          >
            <TimesCircledIcon />
          </ClearInputButton>
        )}
      </InputBorderWrapper>
      <HelperMessagesGroup
        shouldRemoveHelperTextGroupMargin={shouldRemoveHelperTextGroupMargin}
        {...{ isPII }}
      >
        {!hasError && helperTextLeft && (
          <HelperText>{helperTextLeft}</HelperText>
        )}
        {hasError && (
          <HelperText aria-live="assertive" id={errorId} hasError>
            {error}
          </HelperText>
        )}
        {helperTextRight && (
          <HelperText positionEnd>{helperTextRight}</HelperText>
        )}
      </HelperMessagesGroup>
    </InputContainer>
  )
}

const Body3FontStyles = css`
  /* Font Styles for Body 3 */
  line-height: 1rem;
  font-size: 0.8125rem;
`

const InputLabel = styled.label<InputLabelProps>`
  position: absolute;
  pointer-events: none;
  color: ${({ theme, hasError }) => (hasError ? theme.red500 : theme.gray600)};
  border-radius: 0.4rem;
  background: linear-gradient(to top, white 55%, transparent 100%);
  padding-right: 12px;

  &::first-letter {
    text-transform: capitalize;
  }

  ${({ inputHasValue, hasError }) => {
    if (inputHasValue) {
      return css`
        ${Body3FontStyles}
        left: 8px;
        top: -8px;
        padding: 0 8px;
        color: ${({ theme }) => (hasError ? theme.red500 : theme.gray900)};
      `
    }
    return css`
      left: 16px;
      top: 12px;
    `
  }};
`

const ClearInputButton = styled(InputButton)<HasError>`
  display: none;

  svg {
    color: ${({ theme, hasError }) => {
      return hasError ? theme.red500 : theme.purple700
    }};
  }
`

const InputBorderWrapper = styled.div<WrapperProps>`
  ${Body1FontStyles}
  position: relative;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  background: ${({ theme }) => theme.white};
  border-color: ${({ theme, hasError }) => {
    if (hasError) return theme.red500
    return theme.gray600
  }};
  min-height: 48px;
  padding: 10px 12px 0 16px;
  border-style: solid;
  border-width: 1px;
  cursor: text;
  box-sizing: border-box;

  &:focus-within {
    border-width: 2px;
    border-color: ${({ theme, hasError }) => {
      return hasError ? theme.red500 : theme.plum
    }};
    ${ClearInputButton} {
      display: flex;
    }
    ${InputLabel} {
      ${Body3FontStyles}
      left: 8px;
      top: -8px;
      background: linear-gradient(to top, white 60%, transparent 100%);
      padding: 0 8px;
      color: ${({ theme }) => theme.gray900};
    }
  }
`
