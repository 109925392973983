import { screenSizes } from '@shipt/design-system-themes'
import styled from 'styled-components'
import { spacing } from '@/theme/tokens'
import { getCMSWrapperProps } from '@/components/CMS/utils/getCMSWrapperProps'

type CMSContainerProps = {
  id: string
  contentTypeId: string
}

export const CMSContainer = styled.section.attrs<CMSContainerProps>(
  ({ contentTypeId, id }) =>
    getCMSWrapperProps({ content_type_id: contentTypeId, id })
)<CMSContainerProps>`
  width: 100%;
  margin-left: ${spacing(0)};
  margin-right: ${spacing(0)};
  padding: ${spacing(0, 'lg')};

  @media ${screenSizes.tablet} {
    margin-left: ${spacing(36)};
    margin-right: ${spacing(36)};
  }

  @media ${screenSizes.smDesktop} {
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: ${spacing(40)};
    padding-right: ${spacing(40)};
  }
`
