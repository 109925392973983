import styled from 'styled-components'
import { hideVisually } from 'polished'
import { type ReactNode } from 'react'

type Props = { children: ReactNode }

export function VisuallyHidden({ children }: Props) {
  return <Hidden>{children}</Hidden>
}

const Hidden = styled.span`
  ${hideVisually}
`
