import { useCallback, useEffect, useMemo } from 'react'
import { isKeyboardUser } from '@/utils/detectKeyboardUser'

// Custom hook that checks if a user clicks outside the ref or a keyboard user navigates outside the ref.
// We use shouldCheck to prevent any unnecessary func calls.
export const useDetectFocusOutside = (
  refs: React.RefObject<HTMLElement> | React.RefObject<HTMLElement>[],
  func: () => void,
  shouldCheck = true
) => {
  // Allow for multiple refs
  const refArray = useMemo(() => (Array.isArray(refs) ? refs : [refs]), [refs])

  const checkIsOutside = useCallback(
    (e: Event) => {
      return refArray.every(
        (ref) =>
          ref?.current &&
          e.target instanceof Node &&
          !ref.current.contains(e.target)
      )
    },
    [refArray]
  )

  const detectClickOutside = useCallback(
    (e: MouseEvent | TouchEvent) => {
      const isOutside = checkIsOutside(e)
      if (shouldCheck && isOutside) {
        func()
      }
    },
    [func, checkIsOutside, shouldCheck]
  )

  const detectKeyboardFocusOutside = useCallback(
    (e: FocusEvent) => {
      const isOutside = checkIsOutside(e)
      if (shouldCheck && isKeyboardUser() && isOutside) {
        func()
      }
    },
    [func, checkIsOutside, shouldCheck]
  )
  useEffect(() => {
    window.addEventListener('click', detectClickOutside, true)
    window.addEventListener('touchstart', detectClickOutside, true)
    window.addEventListener('focusin', detectKeyboardFocusOutside, true)
    return () => {
      window.removeEventListener('click', detectClickOutside, true)
      window.removeEventListener('touchstart', detectClickOutside, true)
      window.removeEventListener('focusin', detectKeyboardFocusOutside, true)
    }
  }, [detectClickOutside, detectKeyboardFocusOutside])
}
