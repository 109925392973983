import styled from 'styled-components'
import { screenSizes } from '@shipt/design-system-themes'

export const CMSFullBleed = styled.div<{
  $background_color?: string | null
}>`
  background-color: ${({ $background_color }) => $background_color};
  width: 100%;

  @media ${screenSizes.smDesktop} {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
`
